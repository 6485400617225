import { createIcon } from '@chakra-ui/icons';
import * as React from 'react';

export const NetworkIcon = createIcon({
  displayName: 'NetworkIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02942 0 0 4.02944 0 9C0 13.9706 4.02942 18 9 18ZM11.9696 8C11.7616 4.60771 10.5114 2 9 2C7.48865 2 6.2384 4.60771 6.0304 8H11.9696ZM11.9696 10C11.7616 13.3923 10.5114 16 9 16C7.48865 16 6.2384 13.3923 6.0304 10H11.9696ZM4.03052 8C4.14478 6.13895 4.57434 4.44892 5.22095 3.10677C3.54944 4.18088 2.36462 5.94672 2.07092 8H4.03052ZM2.07092 10H4.03052C4.14478 11.8611 4.57434 13.5511 5.22095 14.8932C3.54944 13.8191 2.36462 12.0533 2.07092 10ZM13.9695 10H15.9291C15.6354 12.0533 14.4506 13.8191 12.7791 14.8932C13.4257 13.5511 13.8552 11.8611 13.9695 10ZM15.9291 8H13.9695C13.8552 6.13895 13.4257 4.44892 12.7791 3.10677C14.4506 4.18088 15.6354 5.94672 15.9291 8Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 18 18',
});
