import { createIcon } from '@chakra-ui/icons';
import * as React from 'react';

export const RaidGuildIcon = createIcon({
  displayName: 'RaidGuildIcon',
  path: (
    <g fill="currentColor">
      <path
        d="M16.8,17.2c8.6,2.6,17.2,5.3,25.8,7.9c5.9,1.8,11.8,3.5,17.6,5.6c2,0.7,4.1,1.9,5.6,3.4c23.8,23.7,47.5,47.4,71.3,71.2
			c2.7,2.7,2.7,2.7,5.4,0c6.5-6.5,13.1-13.1,19.6-19.6c17.9-17.7,35.7-35.5,53.6-53.1c1.3-1.3,3.4-2.1,5.2-2.7
			c12.9-4.1,25.9-8.1,38.9-12.1c1.1-0.3,2.2-0.5,3.9-0.9c-1.2,4-2.3,7.5-3.4,11c-3.4,11-6.8,21.9-10.4,32.9c-0.6,1.8-1.7,3.6-3.1,5
			c-24,24.1-48,48.1-72.1,72.2c-2.6,2.6-2.6,2.6,0,5.2c11.4,11.4,22.9,22.9,34.3,34.3c2.4,2.4,3.1,2.3,4.4-0.7
			c4.3-9.6,7.2-19.5,7.2-30.1c0-2.3,0.8-3.3,3.2-3.3c4.1,0.1,8.2,0,12.2,0c1.4,0,2.6,0.3,2.4,2c-0.6,5.8-0.9,11.6-2,17.3
			c-2.8,13.6-8.2,26.2-16.7,37.3c-1.6,2.1-1.4,3.4,0.4,5.2c17.3,17.1,34.5,34.4,51.7,51.6c2.7,2.7,2.6,2.9-0.2,5.4
			c-3.5,3.3-6.9,6.7-10.2,10.3c-2.4,2.6-2.6,2.7-5.1,0.3c-17.2-17.2-34.4-34.4-51.6-51.6c-2.7-2.7-2.7-2.7-5.9-0.4
			c-13,9.2-27.4,14.9-43.3,17c-3.1,0.4-6.3,0.4-9.5,0.6c-2.1,0.2-3.1-0.6-3-2.8c0.1-3.9,0.1-7.8,0-11.7c0-2.1,0.8-2.9,3-3
			c10.5-0.1,20.4-2.7,29.9-7.1c0.9-0.4,1.7-1,2.8-1.6c-0.8-1-1.4-1.8-2.1-2.5c-11.6-11.6-23.2-23.2-34.8-34.9
			c-1.7-1.7-2.6-1.5-4.2,0.1c-11.5,11.6-23.1,23.1-34.6,34.7c-2.4,2.4-2.3,2.6,0.7,4.1c9.7,4.7,20.1,6.9,30.8,7.7
			c2.1,0.1,3,1,2.9,3.1c-0.1,3.8-0.1,7.7,0,11.5c0.1,2.4-1.1,3.1-3.2,3c-19.7-1-37.7-6.8-53.7-18.6c-3-2.2-3-2.2-5.5,0.3
			c-17.1,17.1-34.2,34.2-51.3,51.3c-2.8,2.8-2.7,2.7-5.6,0c-0.5-0.5-1.1-1-1.7-1.5c-1.5-1.5-9.7-8.3-11.4-11
			c0.1-0.1,11.7-11.7,11.8-11.8c14.6-14.5,29.1-29.1,43.7-43.6c1.3-1.2,1.2-2.3,0.1-3.7c-6.6-8.3-11.1-17.8-14.3-27.8
			c-2.7-8.3-4.1-16.8-4.4-25.5c-0.1-2.4,0.8-3.2,3.2-3.2c3.7,0.1,7.5,0.1,11.2,0c2.2-0.1,3.1,0.6,3.2,2.9
			c0.4,10.3,2.8,20.1,7.1,29.4c1.4,3,1.7,3.1,4.1,0.7c11.3-11.2,22.5-22.5,33.8-33.8c2.7-2.7,2.7-2.7,0.1-5.4
			c-24-24-48-47.9-71.9-71.9c-1-1-2.1-2.3-2.5-3.6c-2.9-8.8-5.5-17.6-8.3-26.4c-1.8-5.6-3.6-11.3-5.3-16.9
			C16.8,18.2,16.8,17.7,16.8,17.2z M33.6,40.5c-0.2,0.1-0.4,0.3-0.6,0.4c1.9,5.9,3.7,11.9,5.7,17.8c0.4,1.2,1.5,2.2,2.4,3.1
			C55.5,76,69.9,90.2,84.2,104.4c33.1,33,66.1,66.1,99.2,99.1c4.2,4.2,4.3,4.2,8.7,0.1c2.5-2.4,2.5-2.4,0.1-4.8
			C140,146.7,87.8,94.5,35.6,42.3C35,41.6,34.3,41.1,33.6,40.5z M40.4,33.7c-0.2,0.3-0.5,0.6-0.7,0.9c0.9,0.6,1.9,1.1,2.6,1.9
			c51.6,51.5,103.1,103,154.6,154.6c5,5,3.8,4.4,8.3-0.7c1.5-1.7,1.5-3.1-0.3-4.8C156.7,137.4,108.5,89.2,60.3,41
			c-0.7-0.7-1.5-1.5-2.4-1.8C52.1,37.3,46.3,35.5,40.4,33.7z M240,34c-0.2-0.2-0.3-0.4-0.5-0.7c-5.1,1.6-10.3,3.2-15.4,4.9
			c-1.5,0.5-3,1.4-4.2,2.5c-23.2,23-46.3,46-69.5,69c-4.5,4.5-4.5,4.5,0.1,9.1c2.3,2.3,2.5,2.3,4.8,0c27.6-27.6,55.2-55.2,82.9-82.9
			C238.9,35.3,239.5,34.6,240,34z M247,40.9c-0.2-0.2-0.4-0.3-0.5-0.5c-0.8,0.7-1.5,1.4-2.3,2.1C227,59.7,209.8,77,192.6,94.1
			c-10.4,10.3-20.8,20.6-31.3,30.9c-1.7,1.6-2,2.8-0.1,4.4c1,0.8,1.9,1.7,2.7,2.6c1.6,1.9,2.8,1.6,4.5,0
			c23.8-23.8,47.6-47.6,71.4-71.4c0.8-0.8,1.6-1.8,2-2.8C243.5,52.1,245.2,46.5,247,40.9z M133.4,166.5c-0.6-0.8-1.1-1.6-1.7-2.2
			c-4.8-4.7-4-5.7-9.5-0.1c-10.7,10.7-21.4,21.4-32.1,32.1c-5.1,5.1-5,5,0.9,9.3c1.7,1.2,2.8,1,4.1-0.4
			c12.3-12.3,24.6-24.6,36.9-36.9C132.4,167.8,132.8,167.2,133.4,166.5z M120.2,153.4c-0.4-0.6-0.7-1.1-1.1-1.5c-1-1-2-2-3-3
			c-2.3-2.3-2.4-2.3-4.8,0.1c-11.3,11.3-22.5,22.5-33.8,33.8c-4.7,4.7-4.7,5.6,0.1,10.3c1.3,1.3,2.3,1.2,3.6,0
			c12.5-12.5,25-25,37.5-37.5C119.3,154.9,119.7,154.1,120.2,153.4z"
      />
      <path
        d="M525.4,75.4c-9,24.4-20,47.5-39.2,66.3c0.9,0.4,1.5,0.8,2,0.9c5.5,1.3,11,2.5,16.5,3.8c3.5,0.9,7,1.5,9.5-2
			c0.5-0.7,0.6-1.6,1.2-2.2c0.7-0.6,1.8-1.3,2.6-1.2c0.6,0.1,1.2,1.3,1.7,2.1c0.1,0.2,0,0.5,0.1,0.7c0.3,11.2,0.7,22.5,1,33.7
			c0,0.6,0,1.2,0,1.7c-0.1,1.4,0.3,3.1-1.7,3.3c-1.9,0.3-2.5-1.1-3.3-2.5c-2.3-4.1-4.3-8.3-7.2-11.9c-6.4-8.1-14.4-14.1-24.6-16.7
			c-5.8-1.5-11.3-0.9-17.1,0.8c-5.2,1.5-9.2,4.2-12.4,8.2c-5.8,7.1-9,15.4-10.5,24.3c-3.4,19.1-1.8,37.8,4.9,56.1
			c3.4,9.4,8.5,17.7,16.5,24c6.8,5.3,17.1,8,25.6,3.7c1.7-0.9,2.5-1.9,2.5-3.9c-0.1-12.4,0-24.8-0.1-37.2c0-4.5-2.6-7.7-6.1-9.7
			c-3-1.7-6.4-2.5-9.8-3.6c-1.5-0.5-3.1-0.6-3-2.6c0-2.2,1.6-2.5,3.3-2.4c8.4,0.3,16.8,0.8,25.2,0.8c8.5,0,17-0.4,25.5-0.6
			c2.2-0.1,4.5-0.3,6.7-0.1c0.8,0.1,2.1,1.2,2.1,1.8c0,0.9-0.7,2.1-1.5,2.6c-1.1,0.7-2.6,0.7-3.8,1.2c-4.7,2.3-7.1,6.2-7,11.5
			c0.1,9.7,0.2,19.5,0.5,29.2c0.1,3.8-1.5,6.5-4.3,8.8c-10,7.9-21.6,12-33.8,14.8c-9.9,2.2-20.3,2-30.1,0.6
			c-17.5-2.3-31.3-11.6-40.5-27.1c-6-10.1-8.9-21.1-9.9-32.7c-1.3-14.7,0.4-28.9,7-42.3c5.2-10.6,12.8-19,22.7-25.4
			c1.1-0.7,2.2-1.4,3.6-2.4c-0.7-0.5-1.2-1-1.7-1.3c-13.3-6.6-23.6-16.6-32.2-28.6c-4.2-5.9-8.2-11.9-11.8-18.2
			c-3.5-6-2.4-10.1,3.1-14.4c3.1-2.4,6.4-4.5,9.4-7c3.6-3,7.3-5.9,10.3-9.4c8.1-9.5,6.8-24.5-2.6-32.7c-4.3-3.7-9.2-4.4-14.5-2.1
			c-8.1,3.7-13.8,10-18.5,17.3c-1,1.5-1.8,3.2-2.7,4.8c-2.5,4-2.9,8.3-2.8,12.9c0.1,19.7,0.1,39.5,0,59.2c0,4.1,0.9,7.7,4.8,9.7
			c2,1,4.4,1.5,6.6,2.1c1.4,0.3,2.7,0.7,2.5,2.5c-0.2,1.8-1.5,2.3-3,2.2c-7.1-0.2-14.1-0.5-21.2-0.5c-11.4,0-22.8,0.3-34.2,0.4
			c-1.1,0-2.8-0.6-3.2-1.4c-0.7-1.3-0.1-2.7,1.8-3.1c2.5-0.6,5.1-1.1,7.4-2c4.3-1.7,6.6-4.8,6.6-9.6c0-30.4,0-60.8,0-91.2
			c0-7.9-5.9-15-13.6-16.8c-0.8-0.2-1.8-0.2-2.4-0.7c-0.7-0.7-1.4-1.8-1.3-2.6c0-0.7,1.2-1.3,1.9-1.8c0.3-0.2,0.8-0.1,1.2-0.1
			c9.8,0.2,19.6,0.3,29.5,0.5c3.9,0.1,7.5,1.2,10.6,3.7c3.8,3.2,5.4,7.3,5.5,12.1c0.1,3.8,0,7.6,0,11.4c0.2,0.1,0.4,0.2,0.6,0.2
			c0.4-0.5,0.9-1,1.3-1.5c4.8-5.9,9.3-12.2,14.6-17.7c6.1-6.4,13.3-11.4,22.2-13.3c10.9-2.3,21.4-1.3,30.6,5.7
			c9,6.9,11.7,21.1,6.4,32.6c-3.8,8.4-9.7,14.8-17.2,20c-2.9,2-5.8,4-8.6,6.1c-4.2,3.1-5,7-2.6,11.6c5.1,10.1,11.4,19.2,19.3,27.2
			c5.2,5.2,10.8,10,18.1,12.2c9.1,2.7,17.4,1,24.9-4.9c8.9-7.1,15.4-16.2,20.3-26.3c5.1-10.3,9.5-20.9,14.1-31.5
			c0.5-1.1,0.2-2.8-0.3-3.9c-5.2-9.6-10.5-19.1-15.9-28.6c-4-7-10.4-10.5-18-12.3c-0.9-0.2-1.9-0.4-2.4-1c-0.5-0.6-0.8-1.7-0.6-2.4
			c0.1-0.6,1.1-1.1,1.8-1.5c0.6-0.2,1.3-0.1,2-0.1c10.9,0,21.8,0.1,32.7,0c7.4,0,13.2,3.1,17,9.2c3.8,6,7,12.3,10.3,18.6
			c10.5,19.9,20.9,39.8,31.3,59.7c4.6,8.7,9.1,17.4,13.6,26.1c3.6,7,9.3,11.1,16.9,12.7c1.6,0.3,3.1,0.6,2.9,2.6
			c-0.2,1.9-1.8,2-3.2,2c-1.1,0-2.2,0.1-3.2,0c-1.6-0.1-2.6,0.3-3.3,2c-0.4,0.9-1.6,1.6-2.5,2c-1.7,0.7-3.5,0.9-5.3,1.5
			c-4.4,1.5-6.7,4.5-6.7,9.2c0,18.4-0.4,36.8,0.1,55.2c0.3,11.2,2.3,22.2,9.1,31.7c6.7,9.4,15.9,13.2,27.3,12.1
			c15.5-1.6,24-11.1,28.5-25.3c2.2-7.1,2.8-14.4,2.9-21.7c0.2-15.1-0.2-30.2,0.2-45.2c0.2-8.5-5.3-13.8-11.9-16.2
			c-1-0.4-2.1-0.6-3.1-0.9c-2.1-0.5-4.4-0.7-4.1-3.9c0-0.3-1.6-1-2.4-1c-5.3,0.1-10.6,0.3-16,0.4c-0.7,0-1.7,0.2-2.2-0.1
			c-0.9-0.5-1.9-1.3-2.1-2.2c-0.1-0.6,1-1.8,1.8-2.2c1.3-0.6,2.7-0.7,4.1-1c5.5-1.2,8.9-5.4,8.9-11c0-32.2,0-64.3,0-96.5
			c0-6-2.2-9-8-10.7c-1.6-0.5-3.4-0.5-4.8-1.1c-0.9-0.4-1.9-1.6-2-2.6c-0.1-0.7,1.2-1.9,2-2.2c1.3-0.3,2.8,0,4.2,0
			c4.7,0.2,9.5,0.4,14.2,0.5c7.3,0.1,14.6,0.1,21.9,0c5.2-0.1,10.5-0.5,15.7-0.7c0.3,0,0.7-0.1,1,0c1.6,0.3,3.9,0,4,2.5
			c0.1,2.4-2,2.4-3.7,2.9c-2.6,0.8-5.3,1.6-7.7,2.8c-2.3,1.2-3.4,3.5-3.4,6.2c0,33.7,0,67.5,0,101.2c0,4.1,2.9,7.3,7.7,8.6
			c1.4,0.4,3,0.6,4.4,1.1c0.8,0.3,1.9,0.9,2.2,1.7c0.6,1.7,1.7,1.8,3.2,1.5c1.7-0.3,3.6-0.3,3.9,1.9c0.3,2.2-1.1,3-3.1,3.4
			c-8.8,1.7-13.8,7.8-13.8,16.7c0,16.9,0.2,33.8-0.1,50.7c-0.2,12.9-2.5,25.5-8.7,37.1c-6.3,11.6-15.6,19.6-28.5,22.9
			c-3.6,0.9-7.4,1.2-11.1,1.4c-4.7,0.2-9.3,0.2-14,0c-18.4-0.8-32.1-9.5-40.9-25.6c-6.3-11.4-8.5-24-8.7-36.9
			c-0.3-18.9-0.4-37.8-0.5-56.7c0-3.5-1.5-6-4.6-7.4c-2.5-1.1-5.2-1.6-7.8-2.5c-0.8-0.2-1.7-0.5-2.1-1c-1.4-1.7,0.7-7.1,2.8-7.6
			c2.4-0.6,4.9-0.9,7.3-1.7c1.5-0.5,2.9-1.3,4.1-2.3c3-2.3,3.5-4.3,1.8-7.6c-6-12-12.2-23.9-18.3-35.8c-3-5.9-5.9-11.8-8.9-17.6
			c-0.3-0.6-0.7-1.3-1-1.9C525.9,75.5,525.6,75.4,525.4,75.4z"
      />
      <path
        d="M742,34.8c0.3-0.6,0.5-1.2,0.9-1.8c7.7-10.2,17.9-16.6,30.2-19.1c20.7-4.2,38.4,3.1,50.3,21.9c6.9,10.9,9.5,23.1,10,35.9
			c0.5,13.6-2.1,26.5-7.8,38.8c-4.1,8.9-10,16.4-17.6,22.7c-6.6,5.4-13.6,10-22,12.2c0,0.2,0.1,0.5,0.1,0.7c1.8,0.2,3.7,0.5,5.5,0.5
			c12.6-0.1,25.1-0.4,37.7-0.5c1.8,0,4.3-0.7,4.5,2.2c0.1,2.6-2.2,2.5-4.1,2.9c-7.7,1.6-10.8,4.5-10.7,11.9c0.1,32.8,0,65.7,0,98.5
			c0,3.8,0.1,4.1,3.9,3.9c9-0.4,18.1,0.1,26.8-1.7c13.9-2.8,22.5-12.2,26.2-25.9c0.3-1,0.3-2.1,0.9-2.8c0.5-0.8,1.8-1.8,2.4-1.6
			c0.9,0.3,1.7,1.4,2.1,2.3c0.4,0.9,0.2,2,0.1,3c-0.3,6.2-0.3,12.5-1.1,18.7c-1.3,10.7-6.1,16.3-18,17.5
			c-14.3,1.4-28.6,1.2-42.9,0.8c-14.2-0.5-28.3-0.2-42.5-0.2c-1.1,0-2.2,0-3.2,0c-1.6-0.1-3.1-0.4-3.2-2.4c0-1.9,1.2-2.7,3-3
			c2.4-0.5,4.9-1.1,7.2-2c3.7-1.5,5.4-4.4,5.3-8.5c-0.1-15.7-0.1-31.5,0-47.2c0.1-11.7,0.4-23.3,0.4-35c0-5.7-0.3-11.3-0.5-17
			c-0.1-3.1-1.5-5.6-4.3-6.9c-2.4-1.1-5-1.6-7.5-2.5c-1-0.4-1.9-1.1-2.8-1.7c-1-0.6-1.9-1.6-2.9-1.7c-3.6-0.2-7.2-0.1-10.7,0
			c-0.8,0-2.1,0.3-2.4,0.9c-1.1,2.1-3,2.3-5,2.7c-2.2,0.5-4.5,1.3-6.5,2.5c-2.7,1.6-3.9,4.1-3.9,7.3c0,33,0,66,0,99
			c0,5.5,4.2,9.9,10,10.5c0.7,0.1,1.5-0.2,2,0.2c0.9,0.8,1.7,1.8,2.5,2.7c-0.9,0.7-1.7,2-2.6,2c-3.9,0.2-7.8,0-11.7,0
			c-11.2,0-22.5,0-33.7,0c-4,0-8,0.1-12,0.1c-1.5,0-2.7-0.5-2.8-2.2c0-1.7,1.1-2.4,2.7-2.8c2.2-0.5,4.4-0.8,6.5-1.6
			c3.8-1.6,5.8-4.5,5.8-8.8c0-33.1,0-66.2,0-99.2c0-4.3-2.4-6.8-6-8.4c-2-0.8-4.1-1.2-6.3-1.5c-1.8-0.3-2.7-1-2.8-2.8
			c-0.3-4.5,0.1-5.1,4.4-6.1c1-0.2,2-0.4,2.9-0.7c5.8-2.2,9.3-4.2,9.3-12.2c-0.3-29.7-0.3-59.5,0-89.2c0.1-7.5-5.5-15.6-13.8-17.1
			c-1.6-0.3-3.5-0.5-3.2-2.9c0.3-2.5,2.2-2.3,3.9-2.4c9.6,0,19.2,0,28.7,0c1.4,0,2.8,0.2,4.2,0.4c6.8,1,12,4.8,12.6,13.7
			c0.1,0.8,0.5,1.7,0.7,2.5C741.4,34.5,741.7,34.7,742,34.8z M740.8,91.7c0,1.5,0,3,0,4.5c0,11.2,0,22.3,0,33.5
			c0,4.4,1.5,6.3,5.7,7.2c8,1.8,16,1.5,24-0.3c9.9-2.3,17.7-7.7,22.8-16.5c4.7-8,6.8-16.8,7.4-26c0.8-12.4-1-24.4-5.9-35.9
			c-3.7-8.7-8.5-16.6-16.8-21.8c-7.1-4.5-14.6-5.4-22.1-1.8c-9.4,4.4-15.2,11.5-15.1,22.6C740.9,68.7,740.8,80.2,740.8,91.7z"
      />
      <path
        d="M934.1,163.6c2.7-2.9,4.9-5.4,7.3-7.8c8-8,17.8-12.7,28.9-14c20.6-2.3,36.4,5.8,47,23.6c5.7,9.6,8.2,20.2,9,31.4
			c0.9,11.2-0.4,22.1-3.8,32.7c-3.6,11.3-9.6,21.1-18.2,29.4c-11.7,11.2-25.4,17.1-41.6,17.2c-6.2,0.1-12.5,0.5-18.7,0.4
			c-10.6-0.3-21.3-1.1-31.9-1.3c-8.2-0.2-16.5,0.3-24.7,0.4c-0.9,0-2.2-0.9-2.5-1.7c-0.5-1.5,0.6-2.5,2.1-2.7
			c0.8-0.1,1.7-0.2,2.4-0.5c2.3-0.8,4.7-1.6,6.9-2.7c3.5-1.7,4.9-4.8,4.8-8.6c-0.1-6.4,0-12.8,0-19.2c0-23.4,0-46.8,0-70.2
			c0-10.3-5-16.6-15.1-18.7c-1.6-0.3-2.4-1.1-2.3-2.7c0.1-1.8,1.4-2.3,3-2.3c8,0,16,0,24,0c3.2,0,6.3,0.1,9.5,0.5
			c8.4,1.1,12.5,5.4,13.4,13.8C933.9,161.4,934,162.2,934.1,163.6z M933.8,210c0.2,0,0.3,0,0.5,0c0,16.2,0,32.5,0,48.7
			c0,4.1,1.9,6.3,6,7c6.9,1.2,13.7,1.4,20.6,0c9.3-1.8,17.5-5.6,23.2-13.4c8.2-11.3,10.3-24.3,10-37.9c-0.1-4.8-0.8-9.6-1.9-14.3
			c-2.6-11.9-7.2-22.8-16.2-31.5c-8.5-8.2-19.4-10-29.6-4.1c-7.9,4.6-12.6,11.5-12.6,21C933.8,193.6,933.8,201.8,933.8,210z"
      />
    </g>
  ),
  viewBox: '0 0 1035.2 281.8',
});
